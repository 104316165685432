import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
  loading: false,
  entrepreneurshipFilter: {
    loading: false,
    error: null,
    data: []
  },
  productFilter: {
    loading: false,
    error: null,
    data: []
  },
  productTypeFilter: {
    loading: false,
    error: null,
    data: []
  },
  activityLogs: {
    loading: false,
    error: null,
    data: []
  },
  data: {
    total: 0,
    data: [],
  },
  params: {
    page: 1,
    sizePerPage: 10,
    filters: [],
    sortField: undefined,
    sortOrder: undefined
  },
  error: null,
  takeActionModal: {
    show: false,
    unit: null
  }
};

export const fetchUnits = createAsyncThunk(
  'reservations/fetchUnits',
  async ({params, accessToken}) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/reservations', {
        headers: {
          Authorization: "Bearer " + accessToken
        },
        params
      });

      return {
        response: response.data,
        params
      };
    } catch (err) {
      const errorMessage = {
        name: "fetchUnits error",
        message: err.response.data.message
      };
      throw errorMessage;
    }
  })

export const fetchEntrepreneurshipFilter = createAsyncThunk(
  'reservations/fetchEntrepreneurshipFilter',
  async ({accessToken}) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/entrepreneurships', {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });

      return response.data;
    } catch (err) {
      const errorMessage = {
        name: "fetchEntrepreneurshipFilter error",
        message: err.response.data.message
      };
      throw errorMessage;
    }
  })

export const fetchProductFilter = createAsyncThunk(
  'reservations/fetchProductFilter',
  async ({
           entrepreneurship_id,
           accessToken
         }) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/products', {
        headers: {
          Authorization: "Bearer " + accessToken
        },
        params: {
          entrepreneurship_id
        }
      });

      return response.data;
    } catch (err) {
      const errorMessage = {
        name: "fetchProductFilter error",
        message: err.response.data.message
      };
      throw errorMessage;
    }
  })

export const fetchProductTypeFilter = createAsyncThunk(
  'reservations/fetchProductTypeFilter',
  async ({accessToken}) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/product-types', {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });

      return response.data;
    } catch (err) {
      const errorMessage = {
        name: "fetchProductTypeFilter error",
        message: err.response.data.message
      };
      throw errorMessage;
    }
  })

const reservationSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    toggleTakeActionModal(state, action) {
      state.takeActionModal.show = action.payload.show
      state.takeActionModal.unit = action.payload.unit
    }
  },
  extraReducers: {
    [fetchUnits.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchUnits.fulfilled]: (state, action) => {
      state.loading = false
      state.params = action.payload.params
      state.data.total = action.payload.response.total
      state.data.data = action.payload.response.data
    },
    [fetchUnits.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },

    [fetchEntrepreneurshipFilter.pending]: (state, action) => {
      state.entrepreneurshipFilter.loading = true
      state.entrepreneurshipFilter.error = null
    },
    [fetchEntrepreneurshipFilter.fulfilled]: (state, action) => {
      state.entrepreneurshipFilter.loading = false
      state.entrepreneurshipFilter.data = action.payload.data
    },
    [fetchEntrepreneurshipFilter.rejected]: (state, action) => {
      state.entrepreneurshipFilter.loading = false
      state.entrepreneurshipFilter.error = action.error.message
    },

    [fetchProductFilter.pending]: (state, action) => {
      state.productFilter.loading = true
      state.productFilter.error = null
    },
    [fetchProductFilter.fulfilled]: (state, action) => {
      state.productFilter.loading = false
      state.productFilter.data = action.payload.data
    },
    [fetchProductFilter.rejected]: (state, action) => {
      state.productFilter.loading = false
      state.productFilter.error = action.error.message
    },

    [fetchProductTypeFilter.pending]: (state, action) => {
      state.productTypeFilter.loading = true
      state.productTypeFilter.error = null
    },
    [fetchProductTypeFilter.fulfilled]: (state, action) => {
      state.productTypeFilter.loading = false
      state.productTypeFilter.data = action.payload.data
    },
    [fetchProductTypeFilter.rejected]: (state, action) => {
      state.productTypeFilter.loading = false
      state.productTypeFilter.error = action.error.message
    },
  },
})

export const {toggleTakeActionModal} = reservationSlice.actions

export default reservationSlice.reducer

export const selectAllUnits = (state) => {
  return state.reservations
}
