import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
  loading: false,
  data: {
    total: 0,
    data: [],
  },
  params: {
    page: 1,
    sizePerPage: 10,
    filters: [],
    sortField: undefined,
    sortOrder: undefined
  },
  error: null,
  unreadNotifications: {
    loading: false,
    error: null,
    total: 0,
    data: []
  },
};

export const fetchList = createAsyncThunk(
  'notifications/fetchList',
  async ({params, accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/notifications`, {
        headers: {
          Authorization: "Bearer " + accessToken
        },
        params
      });

      return {
        response: response.data,
        params
      };
    } catch (err) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  })

export const fetchUnreadNotifications = createAsyncThunk(
  'notifications/fetchUnreadNotifications',
  async ({accessToken}, {rejectWithValue}) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/notifications/unread`, {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });

      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
console.log('err.response.data', err.response.data)
      return rejectWithValue(err.response.data)
    }
  })

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchList.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchList.fulfilled]: (state, action) => {
      state.loading = false
      state.params = action.payload.params
      state.data.total = action.payload.response.total
      state.data.data = action.payload.response.data
    },
    [fetchList.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error
    },

    [fetchUnreadNotifications.pending]: (state, action) => {
      state.unreadNotifications.loading = true
      state.unreadNotifications.error = null
    },
    [fetchUnreadNotifications.fulfilled]: (state, action) => {
      state.loading = false
      state.unreadNotifications.total = action.payload.total
      state.unreadNotifications.data = action.payload.data
    },
    [fetchUnreadNotifications.rejected]: (state, action) => {
      state.unreadNotifications.loading = false
      console.log('fetchUnreadNotifications.rejected', action)
      state.unreadNotifications.error = action.payload
    },
  },
})

export default notificationsSlice.reducer
