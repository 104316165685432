import {configureStore} from '@reduxjs/toolkit'

import ThemeOptions from '../features/theme-options'
import notificationsReducer from '../features/notifications/notificationsSlice'
import leadsReducer from '../features/leads/leadsSlice'
import unitsReducer from '../features/units/unitsSlice'
import customersApiReducer from '../features/api/customersApiSlice'
import sellersApiReducer from '../features/api/sellersApiSlice'
import takeUnitSliceReducer from '../features/take-unit/takeUnitSlice'
import requestTakeSliceReducer from '../features/units/requestTakeSlice'
import createCustomerReducer from '../features/customers/CreateCustomerModal/createCustomerSlice'
import editCustomerReducer from '../features/customers/EditCustomerModal/editCustomerSlice'
import estimationsReducer from '../features/estimations/estimationsSlice'
import reservationsReducer from '../features/reservations/reservationsSlice'
import observationReducer from '../features/reservations/observationSlice'
import billOfSaleUploadReducer from '../features/reservations/billOfSaleUploadSlice'
import stockControlReducer from '../features/stock-control/stockControlSlice'
import unitSelectorReducer from '../features/estimations/create/UnitSelectorSlice'
import mapViewReducer from '../features/units/MapView/mapViewSlice'
import freeUnitSliceReducer from '../features/estimations/freeUnitSlice'
import createEstimationReducer from '../features/estimations/create/createEstimationSlice'
import pendingEstimationsReducer from '../features/estimations/pending/pendingEstimationsSlice'
import addEstimationCommentReducer from '../features/estimations/edit/addEstimationCommentSlice'

export default configureStore({
  reducer: {
    ThemeOptions,
    notifications: notificationsReducer,
    leads: leadsReducer,
    units: unitsReducer,
    customersApi: customersApiReducer,
    sellersApi: sellersApiReducer,
    takeUnit: takeUnitSliceReducer,
    requestTake: requestTakeSliceReducer,
    createCustomer: createCustomerReducer,
    editCustomer: editCustomerReducer,
    estimations: estimationsReducer,
    reservations: reservationsReducer,
    observation: observationReducer,
    billOfSaleUpload: billOfSaleUploadReducer,
    stockControl: stockControlReducer,
    unitSelector: unitSelectorReducer,
    mapView: mapViewReducer,
    freeUnit: freeUnitSliceReducer,
    createEstimation: createEstimationReducer,
    pendingEstimations: pendingEstimationsReducer,
    addEstimationComment: addEstimationCommentReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  })
})
